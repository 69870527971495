import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface PageTransitionProps {
  children: React.ReactNode;
}

const PageTransition: React.FC<PageTransitionProps> = ({ children }) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('page-transition-enter');
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);
  
  useEffect(() => {
    // Check for reduced motion preference
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    setPrefersReducedMotion(mediaQuery.matches);
    
    const handleChange = (e: MediaQueryListEvent) => {
      setPrefersReducedMotion(e.matches);
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
    
    // If user prefers reduced motion, skip animation
    if (prefersReducedMotion) {
      setDisplayLocation(location);
      return;
    }
    
    if (location !== displayLocation) {
      setTransitionStage('page-transition-exit-active');
      
      const timeout = setTimeout(() => {
        setDisplayLocation(location);
        setTransitionStage('page-transition-enter');
        
        // Force repaint
        document.body.offsetHeight;
        
        setTransitionStage('page-transition-enter-active');
      }, 300); // Match with CSS transition duration
      
      return () => clearTimeout(timeout);
    }
  }, [location, displayLocation, prefersReducedMotion]);

  return (
    <div className="page-transition-container">
      <div className={prefersReducedMotion ? '' : transitionStage}>
        {children}
      </div>
    </div>
  );
};

export default PageTransition;
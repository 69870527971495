import React, { useState, useEffect } from 'react';

// Fallback component with progressive enhancement for longer loading times
const LoadingFallback: React.FC = () => {
  const [loadingStage, setLoadingStage] = useState(0);
  
  // Show more detailed loading messages as time progresses
  useEffect(() => {
    const stageTimers: NodeJS.Timeout[] = [];
    
    // After 300ms, show a spinner
    stageTimers.push(setTimeout(() => setLoadingStage(1), 300));
    
    // After 1s, show a more detailed message
    stageTimers.push(setTimeout(() => setLoadingStage(2), 1000));
    
    // After 3s, show an even more detailed message with a progress indicator
    stageTimers.push(setTimeout(() => setLoadingStage(3), 3000));
    
    return () => {
      stageTimers.forEach(timer => clearTimeout(timer));
    };
  }, []);
  
  // Prevent flash of loading state for fast connections
  if (loadingStage === 0) {
    return null;
  }
  
  return (
    <div className="flex flex-col items-center justify-center min-h-[50vh] p-8">
      {/* Logo */}
      <img 
        src="logo.png" 
        alt="Jurado Attorneys & Consultants" 
        className="h-16 mb-8 animate-pulse" 
        style={{ 
          objectFit: 'contain',
          animationDuration: '2s' 
        }}
      />
      
      {/* Loading state 1: Just a spinner */}
      {loadingStage === 1 && (
        <div className="w-10 h-10 border-4 border-primary/20 border-t-primary rounded-full animate-spin"></div>
      )}
      
      {/* Loading state 2: Spinner with message */}
      {loadingStage === 2 && (
        <div className="text-center">
          <div className="w-10 h-10 border-4 border-primary/20 border-t-primary rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600 text-lg">Cargando contenido...</p>
        </div>
      )}
      
      {/* Loading state 3: Detailed message for slower connections */}
      {loadingStage === 3 && (
        <div className="text-center">
          <div className="w-10 h-10 border-4 border-primary/20 border-t-primary rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600 text-lg mb-2">Cargando contenido...</p>
          <p className="text-gray-500 text-sm">Esto está tardando más de lo habitual. Gracias por su paciencia.</p>
          
          {/* Progress bar animation */}
          <div className="w-48 h-1 bg-gray-200 rounded-full mt-4 overflow-hidden">
            <div 
              className="h-full bg-primary origin-left" 
              style={{
                animation: 'progress 2.5s ease-in-out infinite',
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingFallback;
// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules for more information

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

// Cache names
const CACHE_NAME = 'jurado-attorneys-cache-v1';
const RUNTIME_CACHE = 'jurado-runtime-cache';

// Resources to precache
const PRECACHE_ASSETS = [
  '/',
  '/index.html',
  '/favicon.png',
  '/logo.png', 
  '/src/main.tsx',
  '/public/images/portfolio/hero.jpg',
];

// Files that we don't want to cache
const EXCLUDED_URLS = [
  '/api/',
  'chrome-extension://'
];

export function register(config?: Config) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(window.location.href);
    
    window.addEventListener('load', () => {
      const swUrl = '/serviceWorker.js'; // Service worker file location
      
      if (process.env.NODE_ENV === 'production') {
        // Use the service worker in production
        registerValidSW(swUrl, config);
      } else {
        // Don't register service worker in development
        checkValidServiceWorker(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                'tabs for this page are closed.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}

// Service worker implementation
self.addEventListener('install', (event: ExtendableEvent) => {
  event.waitUntil(
    caches.open(CACHE_NAME)
      .then(cache => {
        return cache.addAll(PRECACHE_ASSETS);
      })
      .then(() => {
        return (self as any).skipWaiting();
      })
  );
});

self.addEventListener('activate', (event: ExtendableEvent) => {
  // Clean up old caches
  event.waitUntil(
    caches.keys().then(cacheNames => {
      return Promise.all(
        cacheNames.filter(cacheName => {
          return cacheName !== CACHE_NAME && cacheName !== RUNTIME_CACHE;
        }).map(cacheName => {
          return caches.delete(cacheName);
        })
      );
    }).then(() => {
      return (self as any).clients.claim();
    })
  );
});

self.addEventListener('fetch', (event: FetchEvent) => {
  // Skip cross-origin requests and excluded URLs
  if (
    event.request.mode !== 'navigate' ||
    EXCLUDED_URLS.some(url => event.request.url.includes(url))
  ) {
    return;
  }

  event.respondWith(
    fetch(event.request)
      .then(response => {
        // If the response was good, clone it and store it in the cache
        if (response.status === 200) {
          const responseToCache = response.clone();
          caches.open(RUNTIME_CACHE)
            .then(cache => {
              cache.put(event.request, responseToCache);
            });
        }
        return response;
      })
      .catch(() => {
        // If the network request fails, try to serve from cache
        return caches.match(event.request)
          .then(cachedResponse => {
            if (cachedResponse) {
              return cachedResponse;
            }
            // If there's no match in cache, return the offline page
            return caches.match('/index.html');
          });
      })
  );
});

// Handle push notifications
self.addEventListener('push', (event: PushEvent) => {
  const data = event.data?.json() ?? { title: 'Jurado Attorneys & Consultants', body: 'Nueva notificación' };
  
  const options = {
    body: data.body,
    icon: '/logo.png',
    badge: '/favicon-32x32.png',
  };

  event.waitUntil(
    (self as any).registration.showNotification(data.title, options)
  );
});

// Handle notification click
self.addEventListener('notificationclick', (event: NotificationEvent) => {
  event.notification.close();

  event.waitUntil(
    (self as any).clients.openWindow('/')
  );
});
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as serviceWorker from './serviceWorker';

// Create the root and render the app
const rootElement = document.getElementById('root')!;
createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);

// Unregister service worker to prevent caching issues
serviceWorker.unregister();

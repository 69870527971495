import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Menu, X, Phone, Search, ChevronDown, ChevronRight, Star, 
  ShieldCheck, GavelIcon, Scale, Building2, Home, Users,
  Landmark, Store, FileText, MessageSquare
} from 'lucide-react';
import EnhancedLanguageSwitcher from './EnhancedLanguageSwitcher';
import { useLanguage } from '../i18n/LanguageContext';
import PublicImage from './PublicImage';

interface NavItem {
  name: string;
  href: string;
  submenu?: SubMenuItem[];
  isCTA?: boolean;
}

interface SubMenuItem {
  name: string;
  href: string;
  description?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const EnhancedHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const location = useLocation();
  const { t, currentLanguage } = useLanguage();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const subMenuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const navItemRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  const submenuItemRefs = useRef<{ [key: string]: (HTMLAnchorElement | null)[] }>({});
  
  // Services submenu with all 7 services from the Servicios page
  const servicesSubmenu: SubMenuItem[] = [
    { 
      name: currentLanguage === 'es' ? 'Derecho Corporativo' : 'Corporate Law', 
      href: '/servicios#corporativo',
      description: currentLanguage === 'es' ? 'Asesoramiento integral para empresas' : 'Comprehensive advice for companies',
      icon: Scale as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Derecho Comercial' : 'Commercial Law', 
      href: '/servicios#comercial',
      description: currentLanguage === 'es' ? 'Asesoría en transacciones comerciales' : 'Advice on commercial transactions',
      icon: Store as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Litigios' : 'Litigation', 
      href: '/servicios#litigios',
      description: currentLanguage === 'es' ? 'Representación legal en tribunales' : 'Legal representation in courts',
      icon: GavelIcon as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Derecho Bancario' : 'Banking Law', 
      href: '/servicios#bancario',
      description: currentLanguage === 'es' ? 'Asesoramiento para actividades bancarias' : 'Advice for banking activities',
      icon: Landmark as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Derecho Laboral' : 'Labor Law', 
      href: '/servicios#laboral',
      description: currentLanguage === 'es' ? 'Protección de derechos laborales' : 'Protection of labor rights',
      icon: Users as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Derecho de Familia' : 'Family Law', 
      href: '/servicios#familia',
      description: currentLanguage === 'es' ? 'Asuntos familiares y matrimoniales' : 'Family and matrimonial matters',
      icon: Home as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    },
    { 
      name: currentLanguage === 'es' ? 'Derecho de Propiedad Horizontal' : 'Horizontal Property Law', 
      href: '/servicios#propiedad-horizontal',
      description: currentLanguage === 'es' ? 'Régimen de propiedad horizontal' : 'Horizontal property regime',
      icon: Building2 as unknown as React.FC<React.SVGProps<SVGSVGElement>>
    }
  ];

  // Construct navigation items
  const navigation: NavItem[] = [
    { name: t('nav.home'), href: '/' },
    { 
      name: t('nav.services'), 
      href: '/servicios',
      submenu: servicesSubmenu 
    },
    { name: t('nav.portfolio'), href: '/portafolio' },
    { name: t('nav.about'), href: '/nosotros' },
    { name: t('nav.contact'), href: '/contacto', isCTA: true },
  ];

  // Handle scroll events to change header appearance
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeSubmenu && subMenuRefs.current[activeSubmenu] && 
          !subMenuRefs.current[activeSubmenu]?.contains(event.target as Node)) {
        setActiveSubmenu(null);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeSubmenu]);

  // Close search overlay when pressing Escape
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isSearchOpen) {
          setIsSearchOpen(false);
        } else if (activeSubmenu) {
          setActiveSubmenu(null);
        }
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isSearchOpen, activeSubmenu]);

  // Focus search input when search overlay opens
  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  }, [isSearchOpen]);

  // Handle keyboard navigation for dropdowns
  const handleKeyDown = useCallback((e: React.KeyboardEvent, itemIndex: number, itemName: string) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      // If submenu is open, focus first submenu item
      if (activeSubmenu === itemName && submenuItemRefs.current[itemName]?.length) {
        setFocusedItemIndex(0);
        submenuItemRefs.current[itemName][0]?.focus();
      } 
      // If submenu is not open but item has submenu, open it
      else if (navigation[itemIndex].submenu && activeSubmenu !== itemName) {
        setActiveSubmenu(itemName);
        setFocusedItemIndex(-1); // Reset focus index for future use
      }
      // Otherwise go to next main item
      else {
        const nextIndex = (itemIndex + 1) % navigation.length;
        navItemRefs.current[nextIndex]?.focus();
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      // If in submenu, go to previous submenu item or back to main item
      if (activeSubmenu === itemName && focusedItemIndex > 0) {
        const newIndex = focusedItemIndex - 1;
        setFocusedItemIndex(newIndex);
        submenuItemRefs.current[itemName][newIndex]?.focus();
      } else {
        // Go to previous main item
        const prevIndex = itemIndex === 0 ? navigation.length - 1 : itemIndex - 1;
        navItemRefs.current[prevIndex]?.focus();
      }
    } else if (e.key === 'Enter' || e.key === ' ') {
      // If item has submenu, toggle it
      if (navigation[itemIndex].submenu) {
        e.preventDefault();
        setActiveSubmenu(activeSubmenu === itemName ? null : itemName);
      }
    } else if (e.key === 'Escape') {
      // Close submenu
      if (activeSubmenu) {
        e.preventDefault();
        setActiveSubmenu(null);
        navItemRefs.current[itemIndex]?.focus();
      }
    }
  }, [activeSubmenu, focusedItemIndex, navigation]);

  // Handle submenu hover
  const handleSubmenuHover = (itemName: string) => {
    // Only respond to hover on desktop
    if (window.innerWidth >= 768) {
      setActiveSubmenu(itemName);
    }
  };

  // Handle submenu item keyboard navigation
  const handleSubmenuKeyDown = (e: React.KeyboardEvent, index: number, parentName: string) => {
    const submenu = navigation.find(item => item.name === parentName)?.submenu;
    if (!submenu) return;
    
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = (index + 1) % submenu.length;
      setFocusedItemIndex(nextIndex);
      submenuItemRefs.current[parentName][nextIndex]?.focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevIndex = index === 0 ? submenu.length - 1 : index - 1;
      setFocusedItemIndex(prevIndex);
      submenuItemRefs.current[parentName][prevIndex]?.focus();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setActiveSubmenu(null);
      const parentIndex = navigation.findIndex(item => item.name === parentName);
      navItemRefs.current[parentIndex]?.focus();
    }
  };

  // Toggle search overlay
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  // Generate breadcrumb data from current location
  const getBreadcrumbs = () => {
    if (location.pathname === '/') return [];
    
    const paths = location.pathname.split('/').filter(Boolean);
    return paths.map((path, index) => {
      const href = `/${paths.slice(0, index + 1).join('/')}`;
      const navItem = navigation.find(item => item.href === href);
      
      let name = '';
      if (navItem) {
        name = navItem.name;
      } else {
        // Convert path to proper name if not found in navigation
        name = path.charAt(0).toUpperCase() + path.slice(1);
      }
      
      return { name, href };
    });
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <>
      {/* Top Bar - Fixed */}
      <div className="bg-primary text-white py-2 relative z-50">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <button
              onClick={toggleSearch}
              aria-label={t('nav.search')}
              className="text-white hover:text-white/80 transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-white/70 rounded-full p-1"
            >
              <Search className="w-4 h-4" />
            </button>
            <EnhancedLanguageSwitcher />
          </div>
          
          <style jsx="true">{`
            @keyframes pulse {
              0% {
                box-shadow: 0 0 0 0 rgba(0, 51, 102, 0.4);
              }
              70% {
                box-shadow: 0 0 0 8px rgba(0, 51, 102, 0);
              }
              100% {
                box-shadow: 0 0 0 0 rgba(0, 51, 102, 0);
              }
            }
            
            @keyframes fadeIn {
              from {
                opacity: 0;
                transform: translateY(-10px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
            
            .animate-fadeIn {
              animation: fadeIn 0.5s ease-out forwards;
            }
          `}</style>
          <div className="hidden md:flex items-center space-x-2">
            <a 
              href="https://wa.me/50761120649" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="group flex items-center space-x-2 bg-primary text-white px-3 py-1.5 rounded-full shadow-md hover:bg-primary-dark transition-all duration-300 transform hover:scale-105 animate-fadeIn"
              style={{
                animation: 'pulse 2s infinite',
              }}
            >
              <MessageSquare className="w-5 h-5 flex-shrink-0" />
              <span className="font-medium text-sm group-hover:font-bold">{t('nav.whatsapp')}</span>
            </a>
          </div>
        </div>
      </div>

      {/* Main Header - Scrollable */}
      <header 
        className={`sticky top-0 z-40 w-full transition-all duration-300 ${
          isScrolled 
            ? 'bg-white/95 backdrop-blur-md shadow-md py-2' 
            : 'bg-white py-4'
        }`}
      >
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <Link 
              to="/" 
              className="flex items-center transition-transform hover:scale-105 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary/70 rounded-lg"
              aria-label="Jurado Attorneys & Consultants - Home"
            >
              <PublicImage
                src="/logo.png"
                alt="Jurado Attorneys & Consultants"
                className={`transition-all duration-300 ${
                  isScrolled ? 'h-16 md:h-20' : 'h-20 md:h-28'
                }`}
                style={{
                  minWidth: isScrolled ? '150px' : '180px',
                  objectFit: 'contain'
                }}
              />
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-8">
              {navigation.map((item, index) => (
                <div 
                  key={item.name} 
                  className="relative"
                  onMouseEnter={() => item.submenu && handleSubmenuHover(item.name)}
                  onMouseLeave={() => setActiveSubmenu(null)}
                >
                  <Link
                    ref={el => navItemRefs.current[index] = el}
                    to={item.href}
                    className={`
                      nav-link group flex items-center space-x-1 outline-none
                      ${location.pathname === item.href ? 'nav-link-active' : 'text-gray-600'}
                      ${item.isCTA ? 'bg-primary text-white hover:bg-primary-dark px-4 py-2 rounded-md ml-4' : ''}
                    `}
                    onKeyDown={(e) => handleKeyDown(e, index, item.name)}
                    aria-expanded={activeSubmenu === item.name}
                    aria-haspopup={!!item.submenu}
                  >
                    <span>{item.name}</span>
                    {item.submenu && (
                      <ChevronDown 
                        className={`w-4 h-4 transition-transform duration-300 ${
                          activeSubmenu === item.name ? 'rotate-180' : ''
                        }`} 
                      />
                    )}
                    
                    {/* Indicator for active link */}
                    {location.pathname === item.href && !item.isCTA && (
                      <span className="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
                    )}
                  </Link>
                  
                  {/* Dropdown Menu */}
                  {item.submenu && (
                    <div
                      ref={el => subMenuRefs.current[item.name] = el}
                      className={`
                        absolute top-full left-0 mt-1 bg-white rounded-md shadow-lg overflow-hidden
                        transition-all duration-300 origin-top-left z-50
                        ${activeSubmenu === item.name 
                          ? 'opacity-100 scale-100 translate-y-0' 
                          : 'opacity-0 scale-95 -translate-y-2 pointer-events-none'}
                      `}
                      style={{ minWidth: item.name === t('nav.services') ? '580px' : '280px' }}
                    >
                      <div className="p-4 grid gap-2">
                        {item.name === t('nav.services') ? (
                          <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
                            {item.submenu.map((subItem, subIndex) => (
                              <Link
                                key={subItem.name}
                                ref={el => {
                                  if (!submenuItemRefs.current[item.name]) {
                                    submenuItemRefs.current[item.name] = [];
                                  }
                                  submenuItemRefs.current[item.name][subIndex] = el;
                                }}
                                to={subItem.href}
                                className="flex items-start p-3 rounded-md hover:bg-primary/10 transition-colors outline-none focus:ring-2 focus:ring-inset focus:ring-primary/70 relative group"
                                onClick={() => setActiveSubmenu(null)}
                                onKeyDown={(e) => handleSubmenuKeyDown(e, subIndex, item.name)}
                              >
                                {subItem.icon && (
                                  <span className="flex-shrink-0 mr-3 mt-1 text-primary">
                                    <subItem.icon className="w-5 h-5" />
                                  </span>
                                )}
                                <div className="flex-grow">
                                  <span className="block font-medium text-gray-800">{subItem.name}</span>
                                  {subItem.description && (
                                    <span className="block text-sm text-gray-500 mt-1">{subItem.description}</span>
                                  )}
                                  <span className="absolute inset-0 z-0"></span>
                                </div>
                                <span className="text-primary opacity-0 group-hover:opacity-100 transition-opacity flex-shrink-0 self-center ml-1">→</span>
                              </Link>
                            ))}
                          </div>
                        ) : (
                          item.submenu.map((subItem, subIndex) => (
                            <Link
                              key={subItem.name}
                              ref={el => {
                                if (!submenuItemRefs.current[item.name]) {
                                  submenuItemRefs.current[item.name] = [];
                                }
                                submenuItemRefs.current[item.name][subIndex] = el;
                              }}
                              to={subItem.href}
                              className="flex items-start p-3 rounded-md hover:bg-gray-50 transition-colors outline-none focus:ring-2 focus:ring-inset focus:ring-primary/70"
                              onClick={() => setActiveSubmenu(null)}
                              onKeyDown={(e) => handleSubmenuKeyDown(e, subIndex, item.name)}
                            >
                              {subItem.icon && (
                                <span className="flex-shrink-0 mr-3 mt-1 text-primary">
                                  <subItem.icon className="w-5 h-5" />
                                </span>
                              )}
                              <div>
                                <span className="block font-medium text-gray-800">{subItem.name}</span>
                                {subItem.description && (
                                  <span className="block text-sm text-gray-500 mt-1">{subItem.description}</span>
                                )}
                              </div>
                            </Link>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </nav>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden items-center space-x-3">
              <a 
                href="https://wa.me/50761120649" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center bg-primary text-white p-1.5 rounded-full shadow-md"
                aria-label={t('nav.whatsapp')}
              >
                <MessageSquare className="w-5 h-5" />
              </a>
              <button
                onClick={toggleSearch}
                aria-label={t('nav.search')}
                className="text-gray-600 hover:text-primary transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-primary/70 rounded-full p-1"
              >
                <Search className="w-5 h-5" />
              </button>
              <button
                className="text-gray-600 hover:text-primary transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-primary/70 rounded-full p-1"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-expanded={isMenuOpen}
                aria-label={isMenuOpen ? "Close menu" : "Open menu"}
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation - Slide Down Animation */}
        <div
          className={`
            md:hidden bg-white border-t border-gray-100 overflow-hidden transition-all duration-300 origin-top
            ${isMenuOpen ? 'max-h-[80vh] opacity-100' : 'max-h-0 opacity-0'}
          `}
        >
          <div className="container mx-auto px-4 py-2 divide-y divide-gray-100">
            {navigation.map((item) => (
              <div key={item.name} className="py-2">
                {item.submenu ? (
                  <div>
                    <button
                      onClick={() => setActiveSubmenu(activeSubmenu === item.name ? null : item.name)}
                      className={`
                        w-full flex items-center justify-between px-6 py-4 text-lg font-medium
                        ${location.pathname === item.href ? 'text-primary font-semibold' : 'text-gray-600'}
                      `}
                      aria-expanded={activeSubmenu === item.name}
                    >
                      <span>{item.name}</span>
                      <ChevronDown 
                        className={`w-5 h-5 transition-transform duration-300 ${
                          activeSubmenu === item.name ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                    
                    {/* Submenu Items - Slide Animation */}
                    <div 
                      className={`
                        overflow-hidden transition-all duration-300
                        ${activeSubmenu === item.name ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <div className="bg-gray-50 px-4 py-2 mt-2 mb-2 rounded-md mx-6">
                        <div className="grid gap-1">
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className="flex items-center px-4 py-3 text-gray-600 hover:text-primary hover:bg-primary/5 transition-colors rounded"
                              onClick={() => {
                                setActiveSubmenu(null);
                                setIsMenuOpen(false);
                              }}
                            >
                              {subItem.icon && <subItem.icon className="w-5 h-5 mr-3 text-primary" />}
                              <span className="font-medium">{subItem.name}</span>
                              <span className="ml-auto text-primary text-sm">→</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    className={`
                      mobile-nav-link flex items-center justify-between
                      ${location.pathname === item.href ? 'mobile-nav-link-active' : 'text-gray-600'}
                      ${item.isCTA ? 'bg-primary text-white hover:bg-primary-dark rounded-md mt-4' : ''}
                    `}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span>{item.name}</span>
                    <ChevronRight className="w-5 h-5 opacity-50" />
                  </Link>
                )}
              </div>
            ))}
            
            {/* Mobile contact info */}
            <div className="py-4 mt-4">
              <div className="flex flex-col">
                <div className="flex items-center px-6 py-2">
                  <Phone className="w-5 h-5 text-primary mr-3" />
                  <div className="flex flex-col">
                    <span>(507) 214-6766</span>
                    <span>(507) 214-6624</span>
                  </div>
                </div>
                <div className="flex items-center px-6 py-2">
                  <MessageSquare className="w-5 h-5 text-primary mr-3" />
                  <a 
                    href="https://wa.me/50761120649" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-primary hover:text-primary-dark transition-colors"
                  >
                    {t('nav.whatsapp')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Breadcrumbs - Conditional rendering */}
      {breadcrumbs.length > 0 && (
        <div className="bg-gray-50 border-b border-gray-200">
          <div className="container mx-auto px-4 py-2">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <Link
                    to="/"
                    className="inline-flex items-center text-sm text-gray-500 hover:text-primary transition-colors"
                  >
                    <Home className="w-4 h-4 mr-2" />
                    <span className="sr-only sm:not-sr-only">{t('nav.home')}</span>
                  </Link>
                </li>
                
                {breadcrumbs.map((breadcrumb, index) => (
                  <li key={breadcrumb.href} className="inline-flex items-center">
                    <span className="mx-2 text-gray-400 select-none">/</span>
                    {index === breadcrumbs.length - 1 ? (
                      <span className="text-sm text-primary font-medium">{breadcrumb.name}</span>
                    ) : (
                      <Link
                        to={breadcrumb.href}
                        className="text-sm text-gray-500 hover:text-primary transition-colors"
                      >
                        {breadcrumb.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      )}

      {/* Search Overlay */}
      <div
        className={`
          fixed inset-0 bg-black/70 backdrop-blur-sm flex items-start justify-center z-50
          transition-all duration-300
          ${isSearchOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
        `}
        onClick={() => setIsSearchOpen(false)}
      >
        <div 
          className={`
            w-full max-w-3xl bg-white rounded-b-lg shadow-2xl mx-auto 
            transition-all duration-300 transform
            ${isSearchOpen ? 'translate-y-0' : '-translate-y-full'}
          `}
          onClick={e => e.stopPropagation()}
        >
          <div className="p-4 flex items-center border-b">
            <Search className="w-5 h-5 text-gray-400 mr-3" />
            <input
              ref={searchInputRef}
              type="text"
              placeholder={currentLanguage === 'es' ? "Buscar en el sitio..." : "Search the site..."}
              className="flex-1 text-lg outline-none"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  e.preventDefault();
                  setIsSearchOpen(false);
                } else if (e.key === 'Enter') {
                  // In a real implementation, this would trigger the search
                  console.log('Search for:', searchQuery);
                }
              }}
            />
            <button
              className="p-2 text-gray-400 hover:text-gray-600 transition-colors"
              onClick={() => setIsSearchOpen(false)}
              aria-label="Close search"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          {/* Search suggestions/results would go here */}
          <div className="p-4">
            <p className="text-gray-500 text-sm">
              {currentLanguage === 'es' 
                ? "Comience a escribir para buscar..." 
                : "Start typing to search..."}
            </p>
            {/* Demo results */}
            {searchQuery.length > 2 && (
              <div className="mt-4 space-y-4">
                <h3 className="text-sm font-medium text-gray-500">
                  {currentLanguage === 'es' ? "Resultados sugeridos" : "Suggested results"}
                </h3>
                <div className="space-y-2">
                  <Link 
                    to="/servicios" 
                    className="flex items-start p-2 hover:bg-gray-50 rounded transition-colors"
                    onClick={() => setIsSearchOpen(false)}
                  >
                    <Star className="w-4 h-4 text-primary mt-1 mr-3" />
                    <div>
                      <p className="font-medium">{t('nav.services')}</p>
                      <p className="text-sm text-gray-500">
                        {currentLanguage === 'es' 
                          ? "Explore nuestros servicios legales" 
                          : "Explore our legal services"}
                      </p>
                    </div>
                  </Link>
                  <Link 
                    to="/contacto" 
                    className="flex items-start p-2 hover:bg-gray-50 rounded transition-colors"
                    onClick={() => setIsSearchOpen(false)}
                  >
                    <Phone className="w-4 h-4 text-primary mt-1 mr-3" />
                    <div>
                      <p className="font-medium">{t('nav.contact')}</p>
                      <p className="text-sm text-gray-500">
                        {currentLanguage === 'es' 
                          ? "Contacte con nuestro equipo" 
                          : "Contact our team"}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EnhancedHeader;
import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../i18n/LanguageContext';
import { Globe, Check } from 'lucide-react';

const EnhancedLanguageSwitcher: React.FC = () => {
  const { currentLanguage, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [animationState, setAnimationState] = useState<'idle' | 'switching'>('idle');
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handle language change with animation
  const handleLanguageChange = (language: 'es' | 'en') => {
    if (language === currentLanguage) {
      setIsOpen(false);
      return;
    }
    
    // Set animation state to trigger the animation
    setAnimationState('switching');
    
    // After a short delay, update the language and reset animation state
    setTimeout(() => {
      setLanguage(language);
      setAnimationState('idle');
      setIsOpen(false);
    }, 300);
  };

  // Handle keyboard navigation for dropdown
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const langToSwitch = currentLanguage === 'es' ? 'en' : 'es';
      handleLanguageChange(langToSwitch);
    } else if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="relative flex items-center" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        className={`
          flex items-center space-x-2 
          bg-white/10 hover:bg-white/20 
          px-3 py-1.5 rounded-md text-sm font-medium 
          transition-all shadow-sm border border-white/20 backdrop-blur-sm
          focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50
          ${animationState === 'switching' ? 'animate-pulse' : ''}
        `}
        aria-label={`Switch language, current language: ${currentLanguage === 'es' ? 'Spanish' : 'English'}`}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <Globe 
          className={`
            w-4 h-4 transition-transform duration-500
            ${isOpen ? 'rotate-180' : 'rotate-0'}
            ${animationState === 'switching' ? 'animate-spin' : ''}
          `} 
        />
        <div className="flex items-center space-x-1">
          <span 
            className={`
              transition-all duration-300
              ${currentLanguage === 'es' ? 'opacity-100 font-bold scale-110' : 'opacity-70 scale-100'}
            `}
          >
            ES
          </span>
          <span className="text-white/50">/</span>
          <span 
            className={`
              transition-all duration-300
              ${currentLanguage === 'en' ? 'opacity-100 font-bold scale-110' : 'opacity-70 scale-100'}
            `}
          >
            EN
          </span>
        </div>
      </button>

      {/* Dropdown menu */}
      <div
        className={`
          absolute top-full right-0 mt-1 bg-white rounded-md shadow-md overflow-hidden 
          transition-all duration-300 origin-top-right z-50 w-32
          ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}
        `}
      >
        <div className="py-1">
          <button
            onClick={() => handleLanguageChange('es')}
            className={`
              w-full flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors
              ${currentLanguage === 'es' ? 'bg-primary/5 font-medium text-primary' : ''}
            `}
          >
            <span className="flex items-center">
              <span className="inline-block mr-2">🇪🇸</span>
              Español
            </span>
            {currentLanguage === 'es' && <Check className="w-4 h-4 text-primary" />}
          </button>
          <button
            onClick={() => handleLanguageChange('en')}
            className={`
              w-full flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors
              ${currentLanguage === 'en' ? 'bg-primary/5 font-medium text-primary' : ''}
            `}
          >
            <span className="flex items-center">
              <span className="inline-block mr-2">🇺🇸</span>
              English
            </span>
            {currentLanguage === 'en' && <Check className="w-4 h-4 text-primary" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnhancedLanguageSwitcher;
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Language, LanguageContextType } from '../types';
import translations from './translations';

// Create context with default values
const defaultValues: LanguageContextType = {
  currentLanguage: 'es',
  setLanguage: () => {},
  translations,
  t: (key: string) => key,
};

const LanguageContext = createContext<LanguageContextType>(defaultValues);

// Custom hook to use language context
export const useLanguage = () => useContext(LanguageContext);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  // Read the initial language from localStorage or use Spanish as default
  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    try {
      const savedLanguage = localStorage.getItem('language');
      return (savedLanguage === 'en' || savedLanguage === 'es') ? savedLanguage : 'es';
    } catch (error) {
      console.warn('Error accessing localStorage:', error);
      return 'es';
    }
  });

  // Update localStorage when language changes
  useEffect(() => {
    try {
      localStorage.setItem('language', currentLanguage);
    } catch (error) {
      console.warn('Error setting localStorage:', error);
    }
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  // Function to change language
  const setLanguage = (lang: Language) => {
    setCurrentLanguage(lang);
  };

  // Translation function
  const t = (key: string): string => {
    if (translations[key] && translations[key][currentLanguage]) {
      return translations[key][currentLanguage];
    }
    return key; // Fallback to key if translation not found
  };

  const contextValue: LanguageContextType = {
    currentLanguage,
    setLanguage,
    translations,
    t,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
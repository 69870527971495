import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../i18n/LanguageContext';

// Define SEO metadata structure
export interface SEOMetadata {
  title: string;
  description: string;
  canonical?: string;
  ogType?: string;
  ogImage?: string;
  ogImageAlt?: string;
  twitterCard?: string;
  twitterImage?: string;
  structuredData?: Record<string, any>;
  robots?: string;
  alternateUrls?: {
    [lang: string]: string;
  };
  articlePublishedTime?: string;
  articleModifiedTime?: string;
  articleTags?: string[];
}

interface SEOContextProps {
  updateMetadata: (metadata: Partial<SEOMetadata>) => void;
  currentMetadata: SEOMetadata;
}

// Default metadata values
const defaultMetadataValues: SEOMetadata = {
  title: 'Jurado Attorneys & Consultants | Servicios Legales en Panamá',
  description: 'Firma de abogados especializada en servicios legales corporativos, inmigración, propiedad intelectual y más. Ofrecemos asesoría legal experta en Panamá.',
  ogType: 'website',
  twitterCard: 'summary_large_image',
};

// Create SEO context with default values
const SEOContext = createContext<SEOContextProps>({
  updateMetadata: () => {},
  currentMetadata: defaultMetadataValues,
});

interface SEOProviderProps {
  children: ReactNode;
  defaultMetadata?: Partial<SEOMetadata>;
}

export const SEOProvider: React.FC<SEOProviderProps> = ({ children, defaultMetadata = {} }) => {
  const location = useLocation();
  const { currentLanguage } = useLanguage();
  
  // Generate default metadata based on current language
  const getDefaultMetadata = (): SEOMetadata => ({
    title: currentLanguage === 'es' 
      ? 'Jurado Attorneys & Consultants | Servicios Legales en Panamá' 
      : 'Jurado Attorneys & Consultants | Legal Services in Panama',
    description: currentLanguage === 'es'
      ? 'Firma de abogados especializada en servicios legales corporativos, inmigración, propiedad intelectual y más. Ofrecemos asesoría legal experta en Panamá.'
      : 'Law firm specialized in corporate legal services, immigration, intellectual property, and more. We offer expert legal advice in Panama.',
    canonical: `https://jurado.com${currentLanguage === 'en' ? '/en' : ''}${location.pathname}`,
    ogType: 'website',
    ogImage: 'https://jurado.com/og-image.jpg',
    ogImageAlt: 'Jurado Attorneys & Consultants',
    twitterCard: 'summary_large_image',
    twitterImage: 'https://jurado.com/twitter-image.jpg',
    robots: 'index, follow',
    alternateUrls: {
      es: `https://jurado.com${location.pathname}`,
      en: `https://jurado.com/en${location.pathname}`,
    },
  });
  
  // Initialize metadata state
  const [metadata, setMetadata] = useState<SEOMetadata>(() => {
    const defaults = getDefaultMetadata();
    return { ...defaults, ...defaultMetadata };
  });
  
  // Update metadata when location or language changes
  useEffect(() => {
    // Get updated default metadata
    const defaults = getDefaultMetadata();
    
    // Update metadata with new defaults while preserving custom values
    setMetadata(prev => ({
      ...defaults,
      ...prev,
      canonical: defaults.canonical,
      alternateUrls: defaults.alternateUrls,
    }));
    
  }, [location.pathname, currentLanguage]);
  
  // Apply metadata to document whenever metadata changes
  useEffect(() => {
    updateDocumentMetadata(metadata);
  }, [metadata]);
  
  // Update metadata function
  const updateMetadata = (newMetadata: Partial<SEOMetadata>) => {
    setMetadata(prev => ({ ...prev, ...newMetadata }));
  };
  
  // Apply metadata to document head
  const updateDocumentMetadata = (meta: SEOMetadata) => {
    // Update page title
    document.title = meta.title;
    
    // Update meta description
    let descriptionTag = document.querySelector('meta[name="description"]');
    if (!descriptionTag) {
      descriptionTag = document.createElement('meta');
      descriptionTag.setAttribute('name', 'description');
      document.head.appendChild(descriptionTag);
    }
    descriptionTag.setAttribute('content', meta.description);
    
    // Update canonical link
    let canonicalTag = document.querySelector('link[rel="canonical"]');
    if (!canonicalTag) {
      canonicalTag = document.createElement('link');
      canonicalTag.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalTag);
    }
    canonicalTag.setAttribute('href', meta.canonical || `https://jurado.com${location.pathname}`);
    
    // Update OpenGraph tags
    updateMetaTag('og:title', meta.title);
    updateMetaTag('og:description', meta.description);
    updateMetaTag('og:url', meta.canonical || `https://jurado.com${location.pathname}`);
    updateMetaTag('og:type', meta.ogType || 'website');
    updateMetaTag('og:image', meta.ogImage || 'https://jurado.com/og-image.jpg');
    if (meta.ogImageAlt) {
      updateMetaTag('og:image:alt', meta.ogImageAlt);
    }
    
    // Update Twitter Card tags
    updateMetaTag('twitter:card', meta.twitterCard || 'summary_large_image');
    updateMetaTag('twitter:title', meta.title);
    updateMetaTag('twitter:description', meta.description);
    updateMetaTag('twitter:url', meta.canonical || `https://jurado.com${location.pathname}`);
    updateMetaTag('twitter:image', meta.twitterImage || 'https://jurado.com/twitter-image.jpg');
    
    // Update hreflang tags
    updateHreflangTags(meta.alternateUrls || {
      es: `https://jurado.com${location.pathname}`,
      en: `https://jurado.com/en${location.pathname}`,
    });

    // Update structured data
    updateStructuredData(meta.structuredData);
    
    // Update robots meta
    if (meta.robots) {
      updateMetaTag('robots', meta.robots);
    }
    
    // Update article metadata if present
    if (meta.articlePublishedTime) {
      updateMetaTag('article:published_time', meta.articlePublishedTime);
    }
    if (meta.articleModifiedTime) {
      updateMetaTag('article:modified_time', meta.articleModifiedTime);
    }
    if (meta.articleTags && meta.articleTags.length > 0) {
      // Remove existing article:tag meta tags
      document.querySelectorAll('meta[property="article:tag"]').forEach(tag => tag.remove());
      
      // Add new article:tag meta tags
      meta.articleTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', 'article:tag');
        metaTag.setAttribute('content', tag);
        document.head.appendChild(metaTag);
      });
    }
  };
  
  // Helper to update meta tags
  const updateMetaTag = (property: string, content: string) => {
    const isOgProperty = property.startsWith('og:');
    const isTwitterProperty = property.startsWith('twitter:');
    const isArticleProperty = property.startsWith('article:');
    
    const selector = isOgProperty || isArticleProperty 
      ? `meta[property="${property}"]` 
      : `meta[name="${property}"]`;
    
    let tag = document.querySelector(selector);
    
    if (!tag) {
      tag = document.createElement('meta');
      if (isOgProperty || isArticleProperty) {
        tag.setAttribute('property', property);
      } else {
        tag.setAttribute('name', property);
      }
      document.head.appendChild(tag);
    }
    
    tag.setAttribute('content', content);
  };
  
  // Helper to update hreflang tags
  const updateHreflangTags = (alternateUrls: {[lang: string]: string}) => {
    // Remove existing hreflang tags
    document.querySelectorAll('link[rel="alternate"][hreflang]').forEach(tag => tag.remove());
    
    // Add new hreflang tags
    Object.entries(alternateUrls).forEach(([lang, url]) => {
      const hreflangTag = document.createElement('link');
      hreflangTag.setAttribute('rel', 'alternate');
      hreflangTag.setAttribute('hreflang', lang);
      hreflangTag.setAttribute('href', url);
      document.head.appendChild(hreflangTag);
    });
    
    // Add x-default hreflang
    const xDefaultTag = document.createElement('link');
    xDefaultTag.setAttribute('rel', 'alternate');
    xDefaultTag.setAttribute('hreflang', 'x-default');
    xDefaultTag.setAttribute('href', alternateUrls['es'] || 'https://jurado.com/');
    document.head.appendChild(xDefaultTag);
  };
  
  // Helper to update structured data
  const updateStructuredData = (data?: Record<string, any>) => {
    // Remove existing JSON-LD script
    const existingScript = document.querySelector('script[type="application/ld+json"][data-react-helmet="true"]');
    if (existingScript) {
      existingScript.remove();
    }
    
    // Add new JSON-LD if provided
    if (data) {
      const script = document.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.setAttribute('data-react-helmet', 'true');
      script.textContent = JSON.stringify(data);
      document.head.appendChild(script);
    }
  };
  
  return (
    <SEOContext.Provider value={{ updateMetadata, currentMetadata: metadata }}>
      {children}
    </SEOContext.Provider>
  );
};

// Custom hook to use SEO context
export const useSEO = () => useContext(SEOContext);
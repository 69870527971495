import React from 'react';
import { getImagePath, PublicImageProps } from '../utils/publicPath';

/**
 * Image component that handles public paths correctly across environments
 */
const PublicImage: React.FC<PublicImageProps> = ({
  src,
  alt,
  className,
  style,
  width,
  height,
  loading,
  onClick
}) => {
  const resolvedSrc = getImagePath(src);
  
  return (
    <img
      src={resolvedSrc}
      alt={alt}
      className={className}
      style={style}
      width={width}
      height={height}
      loading={loading}
      onClick={onClick}
    />
  );
};

export default PublicImage;
/**
 * Utility to handle public file paths consistently across environments
 * 
 * In development, paths should be absolute from the root (e.g., '/images/logo.png')
 * In production, paths should be relative to the base URL (e.g., 'images/logo.png')
 */

// Environment is handled automatically by the path resolution functions

// Site's domain - defined in one place for easier updates
export const SITE_DOMAIN = window.location.hostname;
export const SITE_URL = `${window.location.protocol}//${SITE_DOMAIN}`;

// Determine if we should use protocol-relative URLs
export const useRelativeDomain = true;

/**
 * Resolves a public file path based on the current environment
 * @param path The path to the public file, starting with '/'
 * @returns The properly formatted path
 */
export function getPublicPath(path: string): string {
  // Ensure path starts with leading slash
  if (!path.startsWith('/') && !path.startsWith('http')) {
    return `/${path}`;
  }
  return path;
}

/**
 * Component props to use with the Image component
 */
export interface PublicImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  loading?: 'lazy' | 'eager';
  onClick?: () => void;
}

/**
 * Image component that handles public paths correctly
 */
export function getImagePath(src: string): string {
  if (src.startsWith('http') || src.startsWith('data:')) {
    // External URLs or data URLs don't need path adjustment
    return src;
  }
  
  // Local path that needs adjustment
  return getPublicPath(src);
}

/**
 * Gets the full URL for a resource based on current domain
 * @param path Path to the resource (without leading slash)
 * @returns Full URL with appropriate domain
 */
export function getFullResourceUrl(path: string): string {
  // Remove leading slash if present
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  return `${SITE_URL}/${cleanPath}`;
}
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import PageTransition from './components/PageTransition';
import LoadingFallback from './components/LoadingFallback';
import ScrollToTop from './components/ScrollToTop';
import { LanguageProvider } from './i18n/LanguageContext';
import { SEOProvider } from './seo/SEOContext';

// Lazy-loaded page components
const Home = lazy(() => import('./pages/Home'));
const Servicios = lazy(() => import('./pages/Servicios'));
const Portafolio = lazy(() => import('./pages/Portafolio'));
const Nosotros = lazy(() => import('./pages/Nosotros'));
const Contacto = lazy(() => import('./pages/Contacto'));
const FAQ = lazy(() => import('./pages/FAQ'));

// Preload critical routes
const preloadHome = () => import('./pages/Home');
const preloadServicesAndPortfolio = () => {
  // Start preloading after a small delay to prioritize main content
  setTimeout(() => {
    import('./pages/Servicios');
    import('./pages/Portafolio');
  }, 1000);
};

// Preload home and frequently accessed pages
preloadHome();
preloadServicesAndPortfolio();

// Wrapper component for routes with transitions
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <PageTransition>
      <Suspense fallback={<LoadingFallback />}>
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/portafolio" element={<Portafolio />} />
          <Route path="/nosotros/*" element={<Nosotros />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </Suspense>
    </PageTransition>
  );
};

function App() {
  return (
    <LanguageProvider>
      <Router basename="/">
        <ScrollToTop />
        <SEOProvider>
          <Layout>
            <AnimatedRoutes />
          </Layout>
        </SEOProvider>
      </Router>
    </LanguageProvider>
  );
}

export default App;

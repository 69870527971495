import { Language } from '../types';

export const translations: Record<string, Record<Language, string>> = {
  // Form Common
  'form.required': {
    es: 'Este campo es obligatorio',
    en: 'This field is required',
  },
  'form.invalid.email': {
    es: 'Por favor ingrese un correo electrónico válido',
    en: 'Please enter a valid email address',
  },
  'form.invalid.phone': {
    es: 'Por favor ingrese un número de teléfono válido',
    en: 'Please enter a valid phone number',
  },
  'form.min.length': {
    es: 'Este campo debe tener al menos {min} caracteres',
    en: 'This field must have at least {min} characters',
  },
  'form.max.length': {
    es: 'Este campo debe tener menos de {max} caracteres',
    en: 'This field must have less than {max} characters',
  },
  'form.next': {
    es: 'Siguiente',
    en: 'Next',
  },
  'form.back': {
    es: 'Anterior',
    en: 'Back',
  },
  'form.submit': {
    es: 'Enviar',
    en: 'Submit',
  },
  'form.saving': {
    es: 'Guardando...',
    en: 'Saving...',
  },
  'form.saved': {
    es: 'Guardado',
    en: 'Saved',
  },
  'form.sending': {
    es: 'Enviando...',
    en: 'Sending...',
  },
  'form.success': {
    es: 'Formulario enviado con éxito',
    en: 'Form submitted successfully',
  },
  'form.error': {
    es: 'Error al enviar el formulario: {error}',
    en: 'Error submitting form: {error}',
  },
  'form.file.drop': {
    es: 'Suelte los archivos aquí...',
    en: 'Drop files here...',
  },
  'form.file.dragOrClick': {
    es: 'Arrastre archivos aquí o haga clic para seleccionar',
    en: 'Drag files here or click to select',
  },
  'form.file.types': {
    es: 'Tipos permitidos',
    en: 'Allowed types',
  },
  'form.file.maxSize': {
    es: 'máx {size}MB',
    en: 'max {size}MB',
  },
  'form.file.tooMany': {
    es: 'Máximo {max} archivos permitidos',
    en: 'Maximum {max} files allowed',
  },
  'form.file.invalidType': {
    es: 'Tipo de archivo no permitido: {name}',
    en: 'Invalid file type: {name}',
  },
  'form.file.tooLarge': {
    es: 'Archivo demasiado grande: {name} (máx {size}MB)',
    en: 'File too large: {name} (max {size}MB)',
  },
  'form.file.count': {
    es: '{current} de {max} archivos',
    en: '{current} of {max} files',
  },
  'form.file.remove': {
    es: 'Eliminar archivo',
    en: 'Remove file',
  },
  'form.complex.title': {
    es: 'Consulta Corporativa',
    en: 'Corporate Inquiry',
  },
  'form.complex.step1': {
    es: 'Información de Contacto',
    en: 'Contact Information',
  },
  'form.complex.step2': {
    es: 'Detalles de la Consulta',
    en: 'Inquiry Details',
  },
  'form.complex.step3': {
    es: 'Información Adicional',
    en: 'Additional Information',
  },
  'form.complex.step4': {
    es: 'Documentos',
    en: 'Documents',
  },
  'form.complex.company': {
    es: 'Nombre de la Empresa',
    en: 'Company Name',
  },
  'form.complex.role': {
    es: 'Cargo/Posición',
    en: 'Role/Position',
  },
  'form.complex.casetype': {
    es: 'Tipo de Caso',
    en: 'Case Type',
  },
  'form.complex.casetype.consult': {
    es: 'Consulta',
    en: 'Consultation',
  },
  'form.complex.casetype.proposal': {
    es: 'Propuesta',
    en: 'Proposal',
  },
  'form.complex.casetype.lawsuit': {
    es: 'Demanda',
    en: 'Lawsuit',
  },
  'form.complex.urgency': {
    es: 'Nivel de Urgencia',
    en: 'Urgency Level',
  },
  'form.complex.urgency.low': {
    es: 'Baja',
    en: 'Low',
  },
  'form.complex.urgency.medium': {
    es: 'Media',
    en: 'Medium',
  },
  'form.complex.urgency.high': {
    es: 'Alta',
    en: 'High',
  },
  'form.complex.preference': {
    es: 'Preferencia de Contacto',
    en: 'Contact Preference',
  },
  'form.complex.preference.email': {
    es: 'Correo Electrónico',
    en: 'Email',
  },
  'form.complex.preference.phone': {
    es: 'Teléfono',
    en: 'Phone',
  },
  'form.complex.preference.video': {
    es: 'Videollamada',
    en: 'Video Call',
  },
  'form.complex.schedule': {
    es: 'Horario Preferido',
    en: 'Preferred Schedule',
  },
  'form.complex.notes': {
    es: 'Notas Adicionales',
    en: 'Additional Notes',
  },
  'form.complex.documents': {
    es: 'Documentos Relevantes',
    en: 'Relevant Documents',
  },
  'form.complex.documents.desc': {
    es: 'Adjunte documentos relevantes para su caso (opcional)',
    en: 'Attach relevant documents for your case (optional)',
  },
  'form.complex.submit': {
    es: 'Enviar Consulta',
    en: 'Submit Inquiry',
  },
  'form.autosaved': {
    es: 'Formulario guardado automáticamente. Puede continuar más tarde.',
    en: 'Form autosaved. You can continue later.',
  },

  // Navigation
  'nav.home': {
    es: 'Inicio',
    en: 'Home',
  },
  'nav.services': {
    es: 'Servicios',
    en: 'Services',
  },
  'nav.portfolio': {
    es: 'Portafolio',
    en: 'Portfolio',
  },
  'nav.about': {
    es: 'Nosotros',
    en: 'About Us',
  },
  'nav.faq': {
    es: 'Preguntas Frecuentes',
    en: 'FAQ',
  },
  'nav.contact': {
    es: 'Contáctenos',
    en: 'Contact Us',
  },
  'nav.search': {
    es: 'Buscar',
    en: 'Search',
  },
  'nav.whatsapp': {
    es: 'Chat por WhatsApp',
    en: 'Chat on WhatsApp',
  },
  'language.switch': {
    es: 'EN',
    en: 'ES',
  },

  // Footer
  'footer.contact': {
    es: 'Contacto',
    en: 'Contact',
  },
  'footer.links': {
    es: 'Enlaces',
    en: 'Links',
  },
  'footer.schedule': {
    es: 'Horario',
    en: 'Business Hours',
  },
  'footer.weekdays': {
    es: 'Lunes a Viernes',
    en: 'Monday to Friday',
  },
  'footer.hours': {
    es: '8:00 AM - 5:00 PM',
    en: '8:00 AM - 5:00 PM',
  },
  'footer.copyright': {
    es: 'Todos los derechos reservados.',
    en: 'All rights reserved.',
  },
  'footer.developer': {
    es: 'Desarrollado por Hooper IT Services',
    en: 'Developed by Hooper IT Services',
  },

  // Home Page
  'home.hero.title': {
    es: 'Asistencia Legal de Confianza',
    en: 'Trusted Legal Assistance',
  },
  'home.hero.subtitle': {
    es: 'Comprometidos con la excelencia y los resultados para nuestros clientes.',
    en: 'Committed to excellence and results for our clients.',
  },
  'home.hero.cta': {
    es: 'Contáctenos Hoy',
    en: 'Contact Us Today',
  },
  'home.services.title': {
    es: 'Nuestros Servicios',
    en: 'Our Services',
  },
  'home.services.subtitle': {
    es: 'Contamos con un equipo especializado para ofrecer soluciones legales integrales.',
    en: 'We have a specialized team to offer comprehensive legal solutions.',
  },
  'home.services.more': {
    es: 'Más Servicios',
    en: 'More Services',
  },
  'home.services.view.all': {
    es: 'Ver todos nuestros servicios',
    en: 'View all our services',
  },
  'home.services.discover': {
    es: 'Descubra nuestra gama completa de servicios legales',
    en: 'Discover our full range of legal services',
  },
  'home.faq.title': {
    es: 'Preguntas Frecuentes',
    en: 'Frequently Asked Questions',
  },
  'home.faq.subtitle': {
    es: 'Encuentre respuestas a las preguntas más comunes sobre nuestros servicios legales',
    en: 'Find answers to the most common questions about our legal services',
  },
  'faq.search': {
    es: 'Buscar en preguntas frecuentes...',
    en: 'Search FAQs...',
  },
  'faq.all': {
    es: 'Todas las categorías',
    en: 'All categories',
  },
  'faq.helpful': {
    es: '¿Fue útil esta respuesta?',
    en: 'Was this helpful?',
  },
  'faq.related': {
    es: 'Preguntas relacionadas:',
    en: 'Related questions:',
  },
  'faq.ask': {
    es: 'Hacer una pregunta',
    en: 'Ask a question',
  },
  'faq.no.results': {
    es: 'No se encontraron resultados',
    en: 'No results found',
  },
  'faq.need.help': {
    es: '¿Aún necesita ayuda?',
    en: 'Still need help?',
  },

  // Services Page
  'services.title': {
    es: 'Nuestros Servicios',
    en: 'Our Services',
  },
  'services.subtitle': {
    es: 'Ofrecemos una amplia gama de servicios legales para satisfacer sus necesidades.',
    en: 'We offer a wide range of legal services to meet your needs.',
  },

  // Portfolio Page
  'portfolio.title': {
    es: 'Nuestro Portafolio',
    en: 'Our Portfolio',
  },
  'portfolio.subtitle': {
    es: 'Nuestros servicios especializados para satisfacer sus necesidades legales',
    en: 'Our specialized services to meet your legal needs',
  },
  'portfolio.housing': {
    es: 'Adquisición de Viviendas',
    en: 'Housing Acquisition',
  },
  'portfolio.housing.desc': {
    es: 'Brindamos asesoría especializada a numerosas familias en la adquisición exitosa tanto de su vivienda principal como de propiedades vacacionales, asegurando tranquilidad y seguridad jurídica en cada paso del proceso.',
    en: 'We provide specialized advice to numerous families in the successful acquisition of both their main residence and vacation properties, ensuring peace of mind and legal security at every step of the process.',
  },
  'portfolio.horizontal': {
    es: 'Propiedad Horizontal',
    en: 'Horizontal Property',
  },
  'portfolio.horizontal.desc': {
    es: 'Hemos liderado exitosamente la incorporación de prestigiosos proyectos inmobiliarios al Régimen de Propiedad Horizontal en la República de Panamá, aportando nuestra experiencia para garantizar un proceso fluido y eficiente.',
    en: 'We have successfully led the incorporation of prestigious real estate projects into the Horizontal Property Regime in the Republic of Panama, contributing our experience to ensure a smooth and efficient process.',
  },
  'portfolio.companies': {
    es: 'Constitución de Sociedades',
    en: 'Company Formation',
  },
  'portfolio.companies.desc': {
    es: 'Facilitamos la creación de sociedades destinadas tanto a la adquisición de bienes inmuebles como al desarrollo de nuevas actividades comerciales, ofreciendo soluciones ágiles y efectivas ajustadas a cada necesidad empresarial.',
    en: 'We facilitate the creation of companies intended for both the acquisition of real estate and the development of new commercial activities, offering agile and effective solutions tailored to each business need.',
  },
  'portfolio.foundations': {
    es: 'Constitución de Fundaciones',
    en: 'Foundation Establishment',
  },
  'portfolio.foundations.desc': {
    es: 'Asesoramos a familias en la constitución de fundaciones que permiten una administración eficiente y protección integral de su patrimonio, garantizando seguridad financiera y bienestar familiar a largo plazo.',
    en: 'We advise families on establishing foundations that allow efficient administration and comprehensive protection of their assets, guaranteeing long-term financial security and family well-being.',
  },
  'portfolio.succession': {
    es: 'Procesos Sucesorios',
    en: 'Succession Processes',
  },
  'portfolio.succession.desc': {
    es: 'Gestionamos exitosamente procesos sucesorios para asegurar que los herederos puedan acceder sin complicaciones a los bienes heredados, protegiendo los intereses familiares con profesionalismo y discreción.',
    en: 'We successfully manage succession processes to ensure that heirs can access inherited assets without complications, protecting family interests with professionalism and discretion.',
  },
  'portfolio.ready': {
    es: '¿Listo para Discutir su Caso?',
    en: 'Ready to Discuss Your Case?',
  },
  'portfolio.help': {
    es: 'Permítanos ayudarle a alcanzar sus objetivos legales con nuestra experiencia probada.',
    en: 'Let us help you achieve your legal objectives with our proven expertise.',
  },
  'portfolio.schedule': {
    es: 'Agendar Consulta',
    en: 'Schedule Consultation',
  },
  'portfolio.stats.cases': {
    es: '', 
    en: '',
  },
  'portfolio.stats.clients': {
    es: 'Clientes Satisfechos',
    en: 'Satisfied Clients',
  },
  'portfolio.stats.experience': {
    es: 'Años de Experiencia',
    en: 'Years of Experience',
  },
  'portfolio.stats.companies': {
    es: 'Empresas Asesoradas',
    en: 'Companies Advised',
  },

  // About Us Page
  'about.title': {
    es: 'Sobre Nosotros',
    en: 'About Us',
  },
  'about.subtitle': {
    es: 'Conozca más sobre nuestra firma y nuestro equipo de profesionales.',
    en: 'Learn more about our firm and our team of professionals.',
  },
  'about.history.title': {
    es: 'NUESTRA HISTORIA',
    en: 'OUR HISTORY',
  },
  'about.history.content': {
    es: 'Fundada en 2011, Jurado Attorneys & Consultants fue creada para expandirnos a las diferentes ramas del derecho y darle continuidad a la trayectoria que en Derecho Notarial brindábamos bajo la denominación de Servicios Notariales Marel desde el año 1995, y que como firma de abogados, Jurado Attorneys & Consultants ha continuado realizando este y otros servicios legales hasta la fecha.\n\nJurado Attorneys & Consultants nació con la visión de ofrecer servicios legales de primer nivel con un enfoque personalizado. A lo largo de más de 25 años, hemos crecido hasta convertirnos en una de las firmas más respetadas del sector.\n\nNuestra trayectoria está marcada por casos exitosos, innovación constante y un compromiso inquebrantable con la excelencia. Hemos participado en algunas de las transacciones más significativas del mercado y establecido precedentes importantes en diversas áreas del derecho.\n\nHoy, con un equipo de profesionales altamente calificados, continuamos expandiendo nuestros horizontes mientras mantenemos los valores fundamentales que nos han definido desde el principio.',
    en: 'Founded in 2011, Jurado Attorneys & Consultants was created to expand into different branches of law and to provide continuity to the trajectory that we had been providing in Notary Law under the name of Notarial Services Marel since 1995, and that as a law firm, Jurado Attorneys & Consultants has continued to perform this and other legal services to date.\n\nJurado Attorneys & Consultants was born with the vision of offering first-class legal services with a personalized approach. Over more than 25 years, we have grown to become one of the most respected firms in the sector.\n\nOur trajectory is marked by successful cases, constant innovation, and an unwavering commitment to excellence. We have participated in some of the most significant transactions in the market and established important precedents in various areas of law.\n\nToday, with a team of highly qualified professionals, we continue to expand our horizons while maintaining the fundamental values that have defined us from the beginning.',
  },
  'about.team.title': {
    es: 'Nuestro Equipo',
    en: 'Our Team',
  },
  'about.values.title': {
    es: 'Nuestros Valores',
    en: 'Our Values',
  },
  'about.process.title': {
    es: 'Nuestro Proceso de Trabajo',
    en: 'Our Work Process',
  },
  'about.recognition.title': {
    es: 'Reconocimientos',
    en: 'Recognition',
  },
  'about.join.title': {
    es: 'Únase a Nuestra Firma',
    en: 'Join Our Firm',
  },
  'about.join.subtitle': {
    es: 'Estamos siempre en busca de talento excepcional para unirse a nuestro equipo.',
    en: 'We are always looking for exceptional talent to join our team.',
  },
  'about.join.cta': {
    es: 'Ver Oportunidades',
    en: 'View Opportunities',
  },

  // Contact Page
  'contact.title': {
    es: 'Contáctenos',
    en: 'Contact Us',
  },
  'contact.subtitle': {
    es: 'Estamos aquí para ayudarle con cualquier consulta legal.',
    en: 'We are here to help you with any legal inquiry.',
  },
  'contact.form.name': {
    es: 'Nombre',
    en: 'Name',
  },
  'contact.form.email': {
    es: 'Correo Electrónico',
    en: 'Email',
  },
  'contact.form.phone': {
    es: 'Teléfono',
    en: 'Phone',
  },
  'contact.form.message': {
    es: 'Mensaje',
    en: 'Message',
  },
  'contact.form.submit': {
    es: 'Enviar Mensaje',
    en: 'Send Message',
  },
  'contact.address.title': {
    es: 'Nuestra Dirección',
    en: 'Our Address',
  },
  'contact.phone.title': {
    es: 'Teléfono',
    en: 'Phone',
  },
  'contact.email.title': {
    es: 'Correo Electrónico',
    en: 'Email',
  },
  'contact.hours.title': {
    es: 'Horario de Atención',
    en: 'Business Hours',
  },
  'contact.hours.value': {
    es: 'Lunes a Viernes: 8:00 AM - 5:00 PM',
    en: 'Monday to Friday: 8:00 AM - 5:00 PM',
  },
  'contact.send.message': {
    es: 'Envíenos un Mensaje',
    en: 'Send Us a Message',
  },
  'contact.fullname': {
    es: 'Nombre Completo',
    en: 'Full Name',
  },
  'contact.email': {
    es: 'Correo Electrónico',
    en: 'Email',
  },
  'contact.phone': {
    es: 'Teléfono',
    en: 'Phone',
  },
  'contact.legal.area': {
    es: 'Área Legal',
    en: 'Legal Area',
  },
  'contact.select.area': {
    es: 'Seleccione un área',
    en: 'Select an area',
  },
  'contact.message': {
    es: 'Mensaje',
    en: 'Message',
  },
  'contact.send': {
    es: 'Enviar Mensaje',
    en: 'Send Message',
  },
  'contact.info': {
    es: 'Información de Contacto',
    en: 'Contact Information',
  },
  'contact.address': {
    es: 'Dirección',
    en: 'Address',
  },
  'contact.phones': {
    es: 'Teléfonos',
    en: 'Phones',
  },
  'contact.email.info': {
    es: 'Correo Electrónico',
    en: 'Email',
  },
  'contact.open.waze': {
    es: 'Abrir en Waze',
    en: 'Open in Waze',
  },
  'contact.open.maps': {
    es: 'Abrir en Maps',
    en: 'Open in Maps',
  },
  'contact.location': {
    es: 'Ubicación',
    en: 'Location',
  },
};

export default translations;
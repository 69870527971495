import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ScrollToTop component - automatically scrolls to top when route changes
 * This is a "behavior" component - it doesn't render anything
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top smoothly with a small delay to ensure transitions have completed
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto' // Use 'auto' for immediate scrolling without animation
      });
    }, 100);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
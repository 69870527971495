import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Mail, Phone, MessageSquare } from 'lucide-react';
import { useLanguage } from '../i18n/LanguageContext';
import EnhancedHeader from './EnhancedHeader';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { t } = useLanguage();

  const navigation = [
    { name: t('nav.home'), href: '/' },
    { name: t('nav.services'), href: '/servicios' },
    { name: t('nav.portfolio'), href: '/portafolio' },
    { name: t('nav.about'), href: '/nosotros' },
    { name: t('nav.faq'), href: '/faq' },
    { name: t('nav.contact'), href: '/contacto' },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      {/* Enhanced Header */}
      <EnhancedHeader />

      {/* Main Content */}
      <main className="flex-grow">{children}</main>

      {/* Footer */}
      <footer className="bg-primary text-white">
        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">{t('footer.contact')}</h3>
              <div className="space-y-2">
                <div className="space-y-1">
                  <p className="flex items-center space-x-2">
                    <Phone className="w-4 h-4" />
                    <span>(507) 214-6766</span>
                  </p>
                  <p className="flex items-center space-x-2">
                    <Phone className="w-4 h-4" />
                    <span>(507) 214-6624</span>
                  </p>
                </div>
                <Link 
                  to="/contacto" 
                  className="flex items-center space-x-2 hover:text-gray-300 transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 rounded"
                >
                  <Mail className="w-4 h-4" />
                  <span>{t('nav.contact')}</span>
                </Link>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">{t('footer.links')}</h3>
              <ul className="space-y-2">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={`
                        hover:text-gray-300 transition-colors relative group
                        focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 rounded px-1
                        ${location.pathname === item.href ? 'text-white font-medium' : 'text-white/90'}
                      `}
                    >
                      {item.name}
                      {/* Subtle animation for active links */}
                      {location.pathname === item.href && (
                        <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-white/40 rounded-full"></span>
                      )}
                      {/* Hover animation for all links */}
                      <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-white/70 rounded-full group-hover:w-full transition-all duration-300"></span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">{t('footer.schedule')}</h3>
              <p>{t('footer.weekdays')}</p>
              <p>{t('footer.hours')}</p>
            </div>
          </div>
          <div className="border-t border-white/20 mt-8 pt-8 text-center">
            <p>
              &copy; {new Date().getFullYear()} Jurado Attorneys & Consultants. {t('footer.copyright')}
              <span className="mx-2 inline-block opacity-50">|</span>
              <a 
                href="https://hooperits.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-white/80 hover:text-white transition-colors duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 rounded"
                aria-label="Hooper IT Services website"
              >
                <span className="group inline-flex items-center">
                  {t('footer.developer')}
                  <span className="block w-full h-0.5 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 bg-white/40 absolute bottom-0 left-0"></span>
                </span>
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;